<template>
    <div class="level_index level_indexII">


        <div class="search">

            <el-form :inline="true" :model="search">
                <el-form-item prop="title">
                    <!-- <el-input v-model="search.title" placeholder="请输入关键字" /> -->
                    <el-select v-model="value1" clearable placeholder="请选择回收人" class="SelfSelect">
                        <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="value2" clearable placeholder="请选择所属医院" class="SelfSelect">
                        <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="value3" clearable placeholder="请选择类型" class="SelfSelect">
                        <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="value4" clearable placeholder="请选择科室" class="SelfSelect">
                        <el-option v-for="item in options4" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="value5" clearable placeholder="请选择封箱时间" class="SelfSelect">
                        <el-option v-for="item in options5" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>

                    <!-- </el-form-item>
                <el-form-item> -->

                    <el-button style="margin-left: 10px;" type="primary" @click="getList(data.size)">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        查询
                    </el-button>
                    <!-- <el-button class="block" @click="toDel()">批量删除</el-button> -->
                </el-form-item>

                <el-form-item style="float: right;margin: 0;">

                    <el-button type="primary" @click="tosearch" style="">
                        高级搜索
                    </el-button>

                </el-form-item>

            </el-form>


        </div>




        <div class="OutputBox">

            <span><i class="fa fa-line-chart" aria-hidden="true"></i>
                <small>医废封箱汇总：共2458包，15352.56KG</small>
            </span>

            <el-button class="block" @click="">明细导出</el-button>
            <el-button class="block" @click="">汇总导出</el-button>

        </div>



        <Fanfu-Table class="FanfuTableBox FanfuTableBoxI" v-model="select" :data="data" :search-height="50"
            :loading="loading" :get-list="getList" stripe border>

            <el-table-column show-overflow-tooltip prop="number" label="医废编号" align="left" />
            <el-table-column show-overflow-tooltip prop="hospital" label="医院" align="left" />
            <el-table-column show-overflow-tooltip prop="Department" label="科室" align="left" />
            <el-table-column show-overflow-tooltip prop="type" label="类型" align="left" width="60" />
            <el-table-column show-overflow-tooltip prop="weight" label="总重量(Kg)" align="left" width="83" />
            <el-table-column show-overflow-tooltip prop="Sealing" label="是否封箱" align="left" width="70">
                <template slot-scope="scope">
                    <el-button type="primary" round v-if="scope.row.Sealing">已封箱</el-button>
                    <el-button type="info" round v-if="!scope.row.Sealing">未封箱</el-button>
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="collect" label="收集转移人" align="left" width="81" />
            <el-table-column show-overflow-tooltip prop="Delivery" label="出库时间" align="left" />
            <el-table-column show-overflow-tooltip prop="Temporary" label="暂存处人员签字" align="center" width="105">
                <template slot-scope="scope">
                    <img src="" alt="" v-bind:src="scope.row.Temporary" class="CoverImage">
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="Third" label="第三方转运人员" align="left" width="105">
                <template slot-scope="scope">
                    <img src="" alt="" v-bind:src="scope.row.Third" class="CoverImage">
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip label="操作" align="center" width="67">
                <template slot-scope="scope">
                    <div class="operate">
                        <el-button type="primary" @click="edit(scope.row)" v-if="scope.row.state" class="operationBtn">
                            恢复
                        </el-button>
                        <el-button type="danger" @click="del(scope.row)" v-if="!scope.row.state" class="operationBtn">
                            删除
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </Fanfu-Table>

        <Search ref="searchRef" v-model="search" @refresh="getList(data.size)" />

    </div>
</template>

<script>
    import Search from './components/WasteSealingSearch'
    import resizeDetector from 'element-resize-detector'


    const list = [{
            number: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            weight: 115,
            Sealing: false,
            collect: "张三",
            Delivery: "2022-02-15 10:15:20",
            Temporary: "http://iph.href.lu/100x200?text=李四&fg=444444&bg=cccccc",
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            state: false
        },
        {
            number: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            weight: 115,
            Sealing: true,
            collect: "张三",
            Delivery: "2022-02-15 10:15:20",
            Temporary: "http://iph.href.lu/100x200?text=李四&fg=444444&bg=cccccc",
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            state: true
        },
        {
            number: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            weight: 115,
            Sealing: true,
            collect: "张三",
            Delivery: "2022-02-15 10:15:20",
            Temporary: "http://iph.href.lu/100x200?text=李四&fg=444444&bg=cccccc",
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            state: true
        },
        {
            number: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            weight: 115,
            Sealing: true,
            collect: "张三",
            Delivery: "2022-02-15 10:15:20",
            Temporary: "http://iph.href.lu/100x200?text=李四&fg=444444&bg=cccccc",
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            state: true
        },
        {
            number: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            weight: 115,
            Sealing: true,
            collect: "张三",
            Delivery: "2022-02-15 10:15:20",
            Temporary: "http://iph.href.lu/100x200?text=李四&fg=444444&bg=cccccc",
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            state: true
        },
        {
            number: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            weight: 115,
            Sealing: true,
            collect: "张三",
            Delivery: "2022-02-15 10:15:20",
            Temporary: "http://iph.href.lu/100x200?text=李四&fg=444444&bg=cccccc",
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            state: true
        },
        {
            number: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            weight: 115,
            Sealing: true,
            collect: "张三",
            Delivery: "2022-02-15 10:15:20",
            Temporary: "http://iph.href.lu/100x200?text=李四&fg=444444&bg=cccccc",
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            state: true
        },
        {
            number: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            weight: 115,
            Sealing: true,
            collect: "张三",
            Delivery: "2022-02-15 10:15:20",
            Temporary: "http://iph.href.lu/100x200?text=李四&fg=444444&bg=cccccc",
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            state: true
        },
        {
            number: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            weight: 115,
            Sealing: true,
            collect: "张三",
            Delivery: "2022-02-15 10:15:20",
            Temporary: "http://iph.href.lu/100x200?text=李四&fg=444444&bg=cccccc",
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            state: true
        },
        {
            number: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            weight: 115,
            Sealing: true,
            collect: "张三",
            Delivery: "2022-02-15 10:15:20",
            Temporary: "http://iph.href.lu/100x200?text=李四&fg=444444&bg=cccccc",
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            state: true
        },
        {
            number: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            weight: 115,
            Sealing: true,
            collect: "张三",
            Delivery: "2022-02-15 10:15:20",
            Temporary: "http://iph.href.lu/100x200?text=李四&fg=444444&bg=cccccc",
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            state: true
        },
        {
            number: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            weight: 115,
            Sealing: true,
            collect: "张三",
            Delivery: "2022-02-15 10:15:20",
            Temporary: "http://iph.href.lu/100x200?text=李四&fg=444444&bg=cccccc",
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            state: true
        },
        {
            number: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            weight: 115,
            Sealing: true,
            collect: "张三",
            Delivery: "2022-02-15 10:15:20",
            Temporary: "http://iph.href.lu/100x200?text=李四&fg=444444&bg=cccccc",
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            state: true
        },
        {
            number: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            weight: 115,
            Sealing: true,
            collect: "张三",
            Delivery: "2022-02-15 10:15:20",
            Temporary: "http://iph.href.lu/100x200?text=李四&fg=444444&bg=cccccc",
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            state: true
        },
        {
            number: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            weight: 115,
            Sealing: true,
            collect: "张三",
            Delivery: "2022-02-15 10:15:20",
            Temporary: "http://iph.href.lu/100x200?text=李四&fg=444444&bg=cccccc",
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            state: true
        },
        {
            number: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            weight: 115,
            Sealing: true,
            collect: "张三",
            Delivery: "2022-02-15 10:15:20",
            Temporary: "http://iph.href.lu/100x200?text=李四&fg=444444&bg=cccccc",
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            state: true
        },
        {
            number: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            weight: 115,
            Sealing: true,
            collect: "张三",
            Delivery: "2022-02-15 10:15:20",
            Temporary: "http://iph.href.lu/100x200?text=李四&fg=444444&bg=cccccc",
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            state: true
        },
        {
            number: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            weight: 115,
            Sealing: true,
            collect: "张三",
            Delivery: "2022-02-15 10:15:20",
            Temporary: "http://iph.href.lu/100x200?text=李四&fg=444444&bg=cccccc",
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            state: true
        },
        {
            number: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            weight: 115,
            Sealing: true,
            collect: "张三",
            Delivery: "2022-02-15 10:15:20",
            Temporary: "http://iph.href.lu/100x200?text=李四&fg=444444&bg=cccccc",
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            state: true
        },
        {
            number: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            weight: 115,
            Sealing: true,
            collect: "张三",
            Delivery: "2022-02-15 10:15:20",
            Temporary: "http://iph.href.lu/100x200?text=李四&fg=444444&bg=cccccc",
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            state: true
        },
        {
            number: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            weight: 115,
            Sealing: true,
            collect: "张三",
            Delivery: "2022-02-15 10:15:20",
            Temporary: "http://iph.href.lu/100x200?text=李四&fg=444444&bg=cccccc",
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            state: true
        },
        {
            number: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            weight: 115,
            Sealing: true,
            collect: "张三",
            Delivery: "2022-02-15 10:15:20",
            Temporary: "http://iph.href.lu/100x200?text=李四&fg=444444&bg=cccccc",
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            state: true
        },
        {
            number: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            weight: 115,
            Sealing: true,
            collect: "张三",
            Delivery: "2022-02-15 10:15:20",
            Temporary: "http://iph.href.lu/100x200?text=李四&fg=444444&bg=cccccc",
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            state: true
        },
        {
            number: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            weight: 115,
            Sealing: true,
            collect: "张三",
            Delivery: "2022-02-15 10:15:20",
            Temporary: "http://iph.href.lu/100x200?text=李四&fg=444444&bg=cccccc",
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            state: true
        },
        {
            number: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            weight: 115,
            Sealing: true,
            collect: "张三",
            Delivery: "2022-02-15 10:15:20",
            Temporary: "http://iph.href.lu/100x200?text=李四&fg=444444&bg=cccccc",
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            state: true
        },

    ]



    export default {
        components: {
            Search
        },
        data() {
            return {
                search: {
                    title: '',
                    name2: '',
                    name3: '',
                    name4: '',
                    name5: '',
                    name6: ''
                },
                loading: false,
                select: [],
                data: {
                    list,
                    page: 1,
                    size: 15,
                    total: list.length
                },


                options1: [{
                    value: '选项1',
                    label: '张三'
                }, {
                    value: '选项2',
                    label: '李四'
                }],
                value1: '',

                options2: [{
                    value: '选项1',
                    label: '天津市双街镇社区卫生服务中心'
                }, {
                    value: '选项2',
                    label: '天津市西青区华苑日华里社区卫生服务中心'
                }],
                value2: '',

                options3: [{
                    value: '选项1',
                    label: '感染性'
                }, {
                    value: '选项2',
                    label: '放射性'
                }],
                value3: '',

                options4: [{
                    value: '选项1',
                    label: '内科一室'
                }, {
                    value: '选项2',
                    label: '实验楼一楼'
                }],
                value4: '',

                options5: [{
                    value: '选项1',
                    label: '今天'
                }, {
                    value: '选项2',
                    label: '昨天'
                }, {
                    value: '选项3',
                    label: '前天'
                }],
                value5: '',


            }
        },
        created() {
            this.getList()
        },
        methods: {
            getList(size) {
                if (size) {
                    this.data.page = 1
                    this.data.size = size
                }
                this.loading = true
                this.select = []
                this.$nextTick(() => {
                    const start = this.data.size * (this.data.page - 1)
                    const end = start + this.data.size
                    this.data.total = list.length
                    const data = []
                    for (let i = start; i < end; i++) {
                        if (list[i]) {
                            data.push(list[i])
                        }
                    }
                    this.data.list = data

                    setTimeout(() => {
                        this.loading = false
                    }, 1000)


                    setTimeout(() => {
                        this.modifyLayout();
                    }, 2000)



                })
            },
            view(row) {
                console.log(row)
            },
            edit(row) {
                console.log(row)
            },
            del(row) {
                console.log(row)

                const h = this.$createElement;
                this.$msgbox({
                    title: '删除',
                    message: h('p', null, [
                        h('span', null, '该操作将删除所选数据，您确定要删除吗？删除后将无法恢复！'),

                    ]),
                    showCancelButton: true,
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';
                            setTimeout(() => {
                                done();
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false;
                                }, 300);
                            }, 3000);
                        } else {
                            done();
                        }
                    }
                }).then(action => {
                    this.$message({
                        type: 'info',
                        message: 'action: ' + action
                    });
                });
            },

            tosearch() {
                // this.$refs.searchRef.open()

            },

            toDel() {
                var row = 1;
                console.log(row)

                const h = this.$createElement;
                this.$msgbox({
                    title: '提示',
                    message: h('p', null, [
                        h('span', null, '您确定要删除所选信息吗？删除后可能会导致数据丢失且删除操作不能撤销！'),

                    ]),
                    showCancelButton: true,
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';
                            setTimeout(() => {
                                done();
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false;
                                }, 300);
                            }, 3000);
                        } else {
                            done();
                        }
                    }
                }).then(action => {
                    this.$message({
                        type: 'info',
                        message: 'action: ' + action
                    });
                });


            },



            modifyLayout() {
                const erd = resizeDetector();

                erd.listenTo(document.getElementsByClassName("search"), element => {
                    const width = element.offsetWidth;
                    const height = element.offsetHeight;

                    var a = 141 + 60 + 0 + height;
                    if ($(".level_indexII .FanfuTableBox")[0]) {

                        $(".level_indexII .FanfuTableBox")[0].setAttribute("style", 'height: calc(100vh - ' +
                            a +
                            'px) !important');

                    }


                    var b = 141 + 60 + 70 + height;
                    if ($(".level_indexII .el-table")[0]) {


                        $(".level_indexII .el-table")[0].setAttribute("style", 'height: calc(100vh - ' + b +
                            'px) !important');
                    }


                });
            }

        },

        mounted() {
            this.modifyLayout();
        },
        created() {
            this.modifyLayout();
        }

    }
</script>
<style scoped>
    .el-button--mini.is-round {
        padding: 4px 6px;
    }

    .SelfSelect {
        /* width: 120px; */
        margin-right: 10px;

        /*    border: 1px red dotted;
    box-sizing: border-box; */

    }

    .SelfSelect:nth-of-type(2) {
        /* width: 150px; */
        margin-right: 10px;
    }

    .SelfSelect:last-of-type {
        width: 150px;
        margin-right: 0px;
    }

    .OutputBox {

        margin: -5px 0 10px 0;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #6A83FC;
        line-height: 20px;
        /* height: 30px; */
    }

    .OutputBox small {
        margin: 0 10px;
    }

    .operationBtn {
        /*    border: 1px red dotted !important;
    box-sizing: border-box; */
        padding: 5px 10px;
    }

    .operationBtn.el-button--primary {
        color: #ffffff;
        background-color: #5BD995 !important;
        border-color: #5BD995 !important;
    }

    .operationBtn.el-button--danger {
        color: #ffffff;
        background-color: #FF754C !important;
        border-color: #FF754C !important;
    }
</style>